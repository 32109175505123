<style>
.page-vote .el-overlay{display: flex; align-items: center; justify-content: center;}
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}
.center{text-align: center;}
.right{text-align: right;}
.margin-top{margin-top: 10px;}
.privat td{background-color:#fffaea !important;}
.blocked td{background-color:#eee !important;}

.tips{margin-top:10px; font-size:12px; color:#981515;}
.el-cascader{width:170px;}
.el-input__inner{width:100%;}

.el-checkbox--mini{padding:4px 8px !important; height:28px !important;}
.el-checkbox--mini .el-checkbox__label{font-size:12px !important; color:#999;}
.box-card{background: #f9f9f9;}
.vote_dialog{max-height: 89%; margin: 5vh auto !important;}
.vote_dialog .el-dialog__body{padding:20px;}
.el-dialog .el-row{padding:6px 0;}
.el-dialog .label{line-height: 28px; font-size: 14px; color:#000;}
.el-dialog .btn-add{display:flex; justify-content: center; align-items: center; font-size:22px; color:#aaa;}
.vote_dialog .el-col{height:100%;}
.vote_dialog .taked{font-size: 16px; color: #777; display: flex; justify-content: flex-end; align-items: center;}
.vote_dialog .taked .number{font-size: 20px; color: #444; text-shadow: 1px 1px 2px #999; font-weight: bold; font-family: "Times New Roma"; padding-right:4px; }

.vote_dialog .scroll{flex:1 1 auto; height:100%; overflow-y: auto; margin:10px -20px 0 -20px; padding: 0 20px 20px 20px; border: solid 1px #e6e6e6;}
.target{border:solid 1px #DCDFE6; border-radius:10px; background:#fbfff9; padding:10px; padding-bottom: 10px;  margin-top:10px; position: relative; box-shadow:3px 3px 15px rgba(0,0,0,0.15); }
.target .el-row{align-items: stretch; flex-wrap:nowrap;}
.target .serial{font-size:18px; font-weight: bold; color:#fff; margin: -4px 0 0 -4px; height:26px; width:26px; border-radius:50px; background:#409EFF; display:flex; justify-content: center; align-items: center;}
.target .img{width:80px; height:100px; object-fit: cover; border:solid 1px #fff; box-shadow: 0 0 5px #aaa; border-radius: 5px; }
.target .no-pic{border: solid 1px #fff; width: 80px; height: 100px; display: flex; justify-content: center; align-items: center; color: #aaa; font-size: 12px; border-radius: 5px; background: #f5f7fa; box-shadow: 0 0 5px #aaa; }
.target .column{flex:1 1 auto; height:auto; display: flex; flex-direction: column; align-items: stretch; justify-content: space-between;}
.target .column.down{justify-content: flex-end;}
.target .title{ flex:0 0 auto; padding-bottom:10px; font-size:18px; font-weight: bold; overflow: hidden; white-space: nowrap; word-break: break-all;}
.target .abstract{flex:1 1 auto; padding-top:10px; min-height: 61px; overflow: hidden;}

.vote_dialog .option-pad{display:flex; flex-wrap: wrap; justify-content: flex-start; align-items: stretch;}
.vote_dialog .option{flex:1 0 auto; max-width:30%;  margin:10px 1%; padding:8px 5px; border:solid 1px #DCDFE6; background:#fff; border-radius: 5px; display:flex; align-items: center; justify-content: space-between;}
.vote_dialog .option-key{font-weight: bold; padding-right:2px;}
.vote_dialog .option-input{font-size:14px; color:#409EFF;}
.cursor{cursor:pointer;}
.vote_dialog .option .el-icon-edit-outline{color:#1f901b; cursor:pointer; padding-left:5px; font-size:16px;}
.vote_dialog .option .el-icon-delete{color:red; cursor:pointer;}
.vote_dialog .el-dialog__footer{display:flex; align-items: center;}
.flex-grow{flex:1 1 auto; text-align: left;}
.bottom{align-self: flex-end;}
.target_dialog{ height:90vh; margin:5vh auto !important }
.target_dialog .big-hint{font-size:17px; color:#777; font-weight: bold}
.target_dialog .el-row:nth-of-type(n+2){margin-top:10px;}
.target_dialog .label{font-size:15px; color:#909399;}
.target_dialog .btn-add{display:flex; justify-content: center; align-items: center; font-size:22px; color:#aaa;}

.target_dialog .img-bar{display:flex; align-items: stretch; overflow-x: auto; flex-wrap: nowrap;}
.target_dialog .img-box{flex:0 0 auto; position: relative; width:60px; height:60px; margin-right:5px; overflow: hidden; border:solid 1px #ccc; border-radius:5px; cursor:pointer;}
.target_dialog .mask{position: absolute; top:0; right:0; padding:2px 4px; font-size:16px; z-index:2; background:rgba(0,0,0,0.7); text-align: center; color:#fff; border-radius:0 0 0 5px;}
.target_dialog .img{width:100%; height:100%; object-fit: cover; }
.target_dialog .el-image-viewer__wrapper{z-index:2021 !important;}

.vote_sum{ max-height:89%; margin:auto !important;}
.vote_sum .el-dialog__body{padding:0 15px;}
.vote_sum .scroll{padding:0; margin:0;}
.vote_sum .sum-box{display: flex; align-items: stretch; padding:15px 10px; border-bottom: solid 1px #eee; cursor:pointer;}
.vote_sum .sum-box:hover{background: #f1f1f6}
.vote_sum .sum-box .img-box{flex:0 0 auto; width: 70px; height:70px; padding:2px; box-shadow: 0 0 3px 1px rgba(128,128,128,0.5)}
.vote_sum .sum-box .img-box .el-image{width:100%; height:100%;}
.vote_sum .sum-box .info-box{flex:1 1 auto; margin:0 20px;  display: flex; flex-direction: column; justify-content: flex-start;}
.vote_sum .sum-box .title{font-size:16px; font-weight: bold; color:#33393a; display: flex; align-items: center;}
.vote_sum .sum-box .vote-box{width: 100%; margin: 10px 0; border-radius: 5px; overflow: hidden; box-shadow: 5px 5px 8px rgba(0,0,0,0.15);}
.vote_sum .sum-box .vote-title{width: 100%; display: flex; justify-content: space-between;}
.vote_sum .sum-box .vote-title span{width: 80px; background: #8e96d0; color: #fff; padding: 5px 10px; font-size: 16px;}
.vote_sum .sum-box .vote-rows{width: 100%; display: flex; justify-content: space-between; border-bottom: solid 1px #e6e6e6;}
.vote_sum .sum-box .vote-rows span{width: 80px; background: #f6f6f6; color: #334b8a; padding: 5px 10px;}

.vote_sum .sum-box .option{font-weight: 14px; color:#53595a; padding:3px 0;}
.vote_sum .sum-box .chart{min-height:120px; width: 100%;}
.vote_sum .check-pad{display: flex; justify-content: space-between; align-items: center; flex-wrap: nowrap; padding: 30px 0; cursor: pointer;}
.vote_sum .check-item{width: 220px; flex: 0 0 auto; display: flex; flex-direction: column; align-items: stretch; box-shadow: 0 0 10px rgba(0,0,0,0.2); border-radius: 10px; overflow: hidden; cursor: pointer; margin: 0 20px;}
.vote_sum .check-item .line{display: flex; align-items: center; padding: 10px}
.vote_sum .check-item .label{flex: 0 0 auto; font-size: 16px; color: #778; width: 80px;}
.vote_sum .check-item .value{flex: 1 1 auto; font-size: 24px; color: #778; font-weight: bold; color: var(--el-color-primary); text-align: right;}

.vote_sum .check-item .check_item{ text-align: center; font-size:18px;  background: var(--el-color-primary); color: #fff; padding: 10px 0;}
.submit_dialog{width:1100px !important; height:89vh; margin:5vh auto !important;}
.submit_dialog .el-dialog__body{padding: 5px 20px;}
.submit_dialog .dialog-footer{display: flex; align-items: center;}

.qrcode_dialog{width:400px; height:500px;}
.qrcode_dialog .el-dialog__body{padding:20px;}
</style>
<style scoped>
.submit-box{display: flex; align-items: center; border: solid 1px #c6d4f3; border-radius: 4px; overflow: hidden; line-height: 20px; width: 110px}
.submit-box.long{width: 169px;}
.submit-label{flex: 0 0 auto; width: 50%; background: #dfe8fc; color: #5075c5; text-align: center; padding: 0; }
.submit-value{flex: 0 0 auto; width: 50%; background: #f9fbff; color: #6f89c3; text-align: center; padding: 0; }
</style>
<template>
    <div class="component page-vote">
        <!-- 编辑框 -->
        <el-dialog width="700px" :title="data_for_edit.id > 0 ? '编辑投票' : '新增投票'" custom-class="vote_dialog" v-model="show_edit" :close-on-click-modal="false">
	        <el-row :gutter="10">
	            <el-col :span="3"><span class="label">投票标题</span></el-col>
	            <el-col :span="17"><el-input v-model="data_for_edit.title" placeholder="请输入投票标题" maxlength="50" show-word-limit size="mini"></el-input></el-col>
	            <el-col :span="4">
	                <el-checkbox v-model="data_for_edit.privat" :true-label="1" :false-label="0" border size="mini">私有投票</el-checkbox>
	            </el-col>
	        </el-row>
	        <el-row :gutter="10">
	            <el-col :span="3"><span class="label">投票说明</span></el-col>
	            <el-col :span="21"><el-input v-model="data_for_edit.explain" type="textarea" rows="6" maxlength="16380" show-word-limit placeholder="纯文字" size="mini"></el-input></el-col>
	        </el-row>
	        <el-row :gutter="10">
	            <el-col :span="3"><span class="label">投票选项</span></el-col>
	            <el-col :span="21">
	            	<el-space wrap>
		            	<el-tag v-for="(label,idx) in data_for_edit.option" :key="idx" closable type="primary" @close="click_del_option(label)" size="medium">{{label}}</el-tag>
		            	<el-button v-if="!show_option_input" icon="el-icon-plus" size="mini" @click="click_add_option">添加选项</el-button>
		            	<el-input v-else v-model="option_btn_label" placeholder="支持、反对" size="mini" style="width:100px;" @keyup.enter="click_add_option_done" @blur="click_add_option_done" ref="option_input"></el-input>

		            </el-space>
	            </el-col>
	        </el-row>
	        <el-row :gutter="10">
	            <el-col :span="3"><span class="label">开放时间</span></el-col>
	            <el-col :span="12">
	                <el-date-picker style="width:100%" v-model="data_for_edit.open_date" type="datetimerange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" size="mini" value-format="x"></el-date-picker>
	            </el-col>
	            <el-col :span="9"><span class="tips">自动开放的时间范围，若未设置，则为开放。</span></el-col>
	        </el-row>
	        <el-row :gutter="10" v-if="gd.me.role>2">
        		<el-col :span="3"><span class="label">可见组织</span></el-col>
	            <el-col :span="8">
	                <el-cascader style="width:100%" v-model="data_for_edit.area_path" :props="cascader_config" size="mini" ref="areas2" placeholder="点击选择"></el-cascader>
	            </el-col>
	            <el-col :span="2" class="center"><span class="label">或</span></el-col>
	            <el-col :span="3"><span class="label">可见小区</span></el-col>
	            <el-col :span="8">
	                <el-select v-model="data_for_edit.estate" multiple collapse-tags clearable placeholder="指定小区" size="mini">
					    <el-option v-for="item in gd.estate" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
	            </el-col>
	        </el-row>
            <template v-if="data_for_edit.list.length >0">
                <el-row :gutter="10">
                    <el-col :span="3"><span class="label">投票上限</span></el-col>
                    <el-col :span="4">
                        <el-input v-model="data_for_edit.target_limit" placeholder="不填不限制" maxlength="50" size="mini"></el-input>
                    </el-col>
                    <el-col :span="17"><span class="tips">针对竞选目标投票时，最多可以投多少个参选目标。不填或填0不限制。</span></el-col>
                </el-row>
                <div class="scroll" ref="scroll">
                    <div class="target" v-for="(item,idx) in data_for_edit.list" :key="idx">
                        <el-row :gutter="10">
                            <el-col :span="1"><div class="serial" v-html="idx+1"></div></el-col>
                            <el-col :span="4" class="center">
                                <el-image v-if="item.cover_img != ''" class="img" :src="gd.base_url+item.cover_img" fit="cover" :preview-src-list="[gd.base_url+item.cover_img]"></el-image>
                                <div v-else class="no-pic">暂无图片</div>
                            </el-col>
                            <el-col :span="item.cover_img != '' ? 15 : 19" class="column">
                                <div class="title" v-html="item.title"></div>
                                <div class="abstract" v-html="item.abstract"></div>
                            </el-col>
                            <el-col :span="4" class="column bottom">
                                <!-- <div class="taked"><span class="number" v-html="show_vote_taked ? item.vote : '~'"></span><span>票</span></div> -->
                                <div class="right"><el-button type="primary" plain size="mini" icon="el-icon-edit" @click="click_edit_target(idx)">编辑</el-button></div>
                                <div class="right margin-top"><el-button type="danger" plain size="mini" icon="el-icon-delete" @click="click_del_target(idx)">删除</el-button></div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </template>
            
	        <el-row v-else :gutter="10">
	        	<el-col class="tips">若不添加竞选目标↓，则对投票主题进行投票↑。</el-col>
	        </el-row>
	        <template #footer class="dialog-footer">
	        	<el-button type="success" plain icon="el-icon-circle-plus" @click="click_add_target">添加竞选目标</el-button>
	        	<div class="flex-grow"></div>
	            <el-button type="primary" @click="click_edit_done" icon="el-icon-circle-check" :disabled="data_for_edit.submit >0">确定保存</el-button>
	            <el-button type="warning" plain @click="show_edit=false" icon="el-icon-circle-close">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 编辑框结束 -->
        <!-- 目标框 -->
        <el-dialog width="760px" custom-class="target_dialog" :title="data_for_target.id>0 ? '编辑目标' : '添加目标'" v-model="show_target" :close-on-click-modal="false">
            <el-row class="label">
                <el-col :span="3" class="red">目标名称*</el-col>
                <el-col :span="21">
                    <el-input v-model="data_for_target.title" placeholder="请输入目标名称" maxlength="256" show-word-limit  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="3">代表图片</el-col>
                <el-col :span="21" class="img-bar">
                    <div class="img-box" v-if="data_for_target.cover_img != ''">
                        <div class="mask"><i class="el-icon-delete" @click="data_for_target.cover_img = ''"></i></div>
                        <el-image class="img" :src="gd.base_url+data_for_target.cover_img" fit="cover" :preview-src-list="[gd.base_url+data_for_target.cover_img]"></el-image>
                    </div>
                    <template v-else>
                    	<div class="img-box btn-add"  @click="click_target_photo_add"><i class="el-icon-picture-outline"></i></div>
                    	<span style="font-size:12px; color:#981515; align-self: center">若未上传，自动从介绍内容中提取第1张图</span>
                    </template>
                </el-col>
                
            </el-row>
            <el-row class="label">
                <el-col :span="3"><span class="label">介绍摘要</span></el-col>
                <el-col :span="21"><el-input v-model="data_for_target.abstract" type="textarea" rows="3" maxlength="100" show-word-limit placeholder="纯文字，用于列表页显示。若不输入，自动从介绍内容中截取" size="mini"></el-input></el-col>
            </el-row>
            <el-row class="label flex-grow">
                <CKEditor :show="show_target" :content="def_content_for_ckeditor" ref="ckeditor"></CKEditor>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_target_done">确 定</el-button>
                <el-button @click="show_target = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 目标框结束 -->
        
        <!-- 统计框 -->
        <el-dialog title="查看投票" :width="vote_sum_width" v-model="show_sum" :close-on-click-modal="false" custom-class="vote_sum">
        	<div class="scroll" v-if="sum_for_show.list">
        		<div class="sum-box" v-for="(item,idx) in sum_for_show.list" :key="idx" @click="click_target_submit(idx)">
        			<div class="img-box" v-if="item.cover_img != ''">
        				<el-image :src="gd.base_url+item.cover_img" fit="cover"></el-image>
        			</div>
        			<div class="info-box">
        				<div>
        					<div class="title" v-html="item.title"></div>
        					<div class="vote-box">
                                <div class="vote-title">
                                    <span>选项</span>
                                    <span>票数</span>
                                    <span>住宅（㎡）</span>
                                    <span>商铺（㎡）</span>
                                    <span>车位（㎡）</span>
                                    <span>面积合计</span>
                                </div>
                                <div class="vote-rows" v-for="(submit,idx1) in item.vote" :key="idx1">
                                    <span v-html="submit.label"></span>
                                    <span v-html="submit.count"></span>
                                    <span v-html="submit.house.toFixed(2)"></span>
                                    <span v-html="submit.shop.toFixed(2)"></span>
                                    <span v-html="submit.parking.toFixed(2)"></span>
                                    <span v-html="(submit.house+submit.shop+submit.parking).toFixed(2)"></span>
                                </div>

        						<!-- <el-space>
        							<el-tag effect="dark" size="small" >{{submit.label}}：{{submit.count}}</el-tag>
        						</el-space> -->
		        				
		        			</div>
        				</div>
        				<div class="abstract" v-html="item.abstract"></div>
        			</div>
        			
        		</div>
        	</div>
        	<div v-if="sum_for_show.check" class="check-pad" @click="click_target_submit">
        		<div class="check-item" v-for="(item,idx) in sum_for_show.check" :key="idx">
        			<div class="line">
                        <span class="label">票数</span>
                        <span class="value" v-html="item.count"></span>
                    </div>
                    <div class="line">
                        <span class="label">住宅（㎡）</span>
                        <span class="value" v-html="item.house.toFixed(2)"></span>
                    </div>
                    <div class="line">
                        <span class="label">商铺（㎡）</span>
                        <span class="value" v-html="item.shop.toFixed(2)"></span>
                    </div>
                    <div class="line">
                        <span class="label">车位（㎡）</span>
                        <span class="value" v-html="item.parking.toFixed(2)"></span>
                    </div>
                    <div class="line">
                        <span class="label">面积合计</span>
                        <span class="value" v-html="(item.house+item.shop+item.parking).toFixed(2)"></span>
                    </div>

        			<div class="check_item" v-html="item.label"></div>
        		</div>
        	</div>

	        <template #footer class="dialog-footer">
	          <el-button type="primary" plain @click="show_sum = false">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 统计框框结束 -->

        <!-- 投票列表框 -->
        <el-dialog :title="'投票详单'+submit_for_show.title" v-model="show_submit" :close-on-click-modal="false" custom-class="submit_dialog" ref="submit_dialog">

            <el-table size="mini" stripe border :data="submit_for_show.list" :max-height="submit_for_show.table_height" header-row-class-name="table-header" :row-class-name="table_row">
                <el-table-column label="投票时间" width="160" align="center">
                    <template #default="scope"><span v-html="format_datetime(scope.row.created_at)"></span></template>
                </el-table-column>
                <el-table-column prop="submit" label="投票" width="80" align="center"></el-table-column>
                <el-table-column prop="truename" label="投票人" width="120" align="center"></el-table-column>
                <el-table-column prop="phone" label="电话号码" width="120" align="center" ></el-table-column>
                <el-table-column prop="idnumber" label="身份证" width="120" align="center" ></el-table-column>
                <el-table-column prop="bufh_name" label="房屋"></el-table-column>
                <el-table-column prop="house" label="住宅" width="80" align="center" ></el-table-column>
                <el-table-column prop="shop" label="商铺" width="80" align="center" ></el-table-column>
                <el-table-column prop="parking" label="车位" width="80" align="center" ></el-table-column>
                
            </el-table>

        	

	        <template #footer class="dialog-footer">
	        	<el-pagination
			      background
			      small
			      @size-change="submit_table_handleSizeChange"
			      @current-change="submit_table_handlePageChange"
			      :current-page="submit_for_show.page"
			      :page-sizes="[2, 10, 15, 20, 30]"
			      :page-size="submit_for_show.size"
			      layout="total, prev, pager, next, jumper"
			      :total="submit_for_show.total">
			    </el-pagination>
			    <div class="grow">&nbsp;</div>  
	        	<el-button type="primary" @click="show_submit = false">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 答卷列表框结束 -->
        <!-- 私密投票二维码框 -->
        <el-dialog title="微信扫描二维码直接访问" v-model="show_qrcode" :close-on-click-modal="false" custom-class="qrcode_dialog">
			<div style="height:100%; display:flex; align-items: center; justify-content: center;">
			    <el-image :src="qrcode_src">
			    	<template #placeholder class="image-slot">
			        	加载中<span class="dot">...</span>
			    	</template>
			    </el-image>
			</div>
	        <template #footer class="dialog-footer">
			    <div class="grow">&nbsp;</div>  
	        	<el-button type="primary" @click="show_qrcode = false">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 私密投票二维码框结束 -->


	    <div class="title-panel">
	        <el-row>
	          <el-col :span="18">
	            <i class="el-icon-trophy" style="color:#999; font-size:36px; margin-right:5px;"></i>
	            <div class="inline"><div class="label">投票选举</div><div class="descript">一次投票内提供多个竞选目标，用户对他们进行投票。也可不设竞选目标，直接对内容进行投票。竞选目标<span class="red">支持图文介绍</span>。</div></div>
	          </el-col>
	          <el-col :span="6" class="btn-col">
	            <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加投票</el-button>
	            <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
	          </el-col>
	        </el-row>
	    </div>

	    <!-- 筛选栏 -->
	    <el-row style="display:flex; align-items: center; background: #f9f9f9; padding:5px;">
		    <el-cascader style="width:180px;" v-model="area" :props="cascader_config" clearable size="mini" ref="area1" placeholder="筛选可见区划"></el-cascader>
		    <div>&nbsp;</div>
		    <el-checkbox v-model="privat" border size="mini">私密投票</el-checkbox>
			<div>&nbsp;</div>
		    <el-date-picker style="width:270px;" v-model="date" type="daterange" unlink-panels range-separator="至" start-placeholder="开放日期起" end-placeholder="开放日期止" value-format="x" :shortcuts="date_shortcut" size="mini"></el-date-picker>
		    <div>&nbsp;</div>
		    <el-input style="width:180px;" size="mini" v-model="keyword" placeholder="搜索标题" clearable></el-input>    
		    <div style="flex-grow:1">&nbsp;</div>  
		    <el-pagination
		      background
		      small
		      @size-change="handleSizeChange"
			  @current-change="handlePageChange"
		      :current-page="page"
		      :page-sizes="[2, 10, 15, 20, 30]"
		      :page-size="size"
		      layout="total, prev, pager, next, jumper"
		      :total="total">
		    </el-pagination>
	    </el-row>
	    <!-- 筛选栏结束 -->
	    <!-- 主体表格 -->
	    <div class="table-box" id="table-box">
	        <el-table size="mini" stripe border :data="list" :max-height="table_height" header-row-class-name="table-header" :row-class-name="table_row">
	        	<!-- <el-table-column prop="id" label="ID" width="60" align="center"></el-table-column> -->
	            <el-table-column prop="area_name" label="可见组织" width="100" align="center"></el-table-column>
	            <el-table-column prop="title" label="投票标题" width="300" :show-overflow-tooltip="true"></el-table-column>
	            <!-- <el-table-column prop="explain" label="投票说明" :show-overflow-tooltip="true"></el-table-column> -->
	            <el-table-column label="开放时间" width="160" align="center">
	                <template #default="scope">{{format_date(scope.row.open_date[0])}}<br>{{format_date(scope.row.open_date[1])}}</template>
	            </el-table-column>
	            <el-table-column prop="click" label="浏览人数" width="100" align="center"></el-table-column>
	            <el-table-column prop="submit" label="投票情况" width="380" align="center">
	            	<template #default="scope">
	            		<el-space wrap :fillRatio="30">
	            			<div class="submit-box long">
	            				<span class="submit-label">票数</span>
	            				<span class="submit-value" v-html="scope.row.submit.count"></span>
	            			</div>
	            			<div class="submit-box long">
	            				<span class="submit-label">面积合计</span>
	            				<span class="submit-value" v-html="(scope.row.submit.house+scope.row.submit.shop+scope.row.submit.parking).toFixed(2)"></span>
	            			</div>
	            			<div class="submit-box">
	            				<span class="submit-label">住宅</span>
	            				<span class="submit-value" v-html="scope.row.submit.house.toFixed(2)"></span>
	            			</div>
	            			<div class="submit-box">
	            				<span class="submit-label">商业</span>
	            				<span class="submit-value" v-html="scope.row.submit.shop.toFixed(2)"></span>
	            			</div>
	            			<div class="submit-box">
	            				<span class="submit-label">车位</span>
	            				<span class="submit-value" v-html="scope.row.submit.parking.toFixed(2)"></span>
	            			</div>
	            			
	            		</el-space>
	
	                </template>
	            </el-table-column>
	            <el-table-column label="状态" width="100" align="center">
	                <template #default="scope">
	                    <span v-if="scope.row.block == 1">下架中</span>
	                    <span v-else-if="scope.row.valid == -1">手动关闭</span>
	                    <span v-else-if="scope.row.valid == 1">手动开放</span>
	                    <template v-else-if="scope.row.valid == 0">
	                        <span>自动</span>
	                        <span v-if="scope.row.open_date[0] == 0 && scope.row.open_date[1]==0">开放</span>
	                        <span v-else-if="scope.row.open_date[0]>now || scope.row.open_date[1]<now">关闭</span>
	                        <span v-else>开放</span>
	                    </template>
	                </template>
	            </el-table-column>
	            <el-table-column fixed="right" label="操作">
	              <template #default="scope">
	                <div class="line">
	                    <el-button @click="click_block(scope.$index)" type="text" size="small" v-if="scope.row.block==0">下架</el-button>
	                    <el-button @click="click_block(scope.$index)" type="text" size="small" v-else>上架</el-button>
	
                        <el-popover placement="bottom" :width="350" trigger="click">
                            <el-card class="box-card">
                                <el-radio-group v-model="scope.row.valid" @change="click_valid(scope.$index)" size="mini">
                                    <el-radio-button label="1">手动开放</el-radio-button>
                                    <el-radio-button label="-1">手动关闭</el-radio-button>
                                    <el-radio-button label="0">自动开放</el-radio-button>
                                </el-radio-group>
                                <el-row :gutter="10" class="tips">
                                    <el-col :span="5"><b>手动开放</b></el-col>
                                    <el-col :span="19">设为开放状态，用户可投票</el-col>
                                </el-row>
                                <el-row :gutter="10" class="tips">
                                    <el-col :span="5"><b>手动关闭</b></el-col>
                                    <el-col :span="19">设为关闭状态，用户不能投票</el-col>
                                </el-row>
                                <el-row :gutter="10" class="tips">
                                    <el-col :span="5"><b>自动开放</b></el-col>
                                    <el-col :span="19">由投票的开放时间段决定，若未设定开放时间段，则判定开放，直到变更开放设置</el-col>
                                </el-row>
                            </el-card>
                            <template #reference>
                            	<el-button type="text" size="small" style="margin:0 10px;">开放设置</el-button>
                            </template>
                            
                        </el-popover>
	    
	                    <el-button @click="click_edit(scope.$index)" type="text" size="small">编辑</el-button>
	                    <el-button @click="click_del(scope.$index)" type="text" size="small">删除</el-button>
	                </div>
	                <div class="line">
		                <el-button v-if="scope.row.submit.count>0" @click="click_sum(scope.$index)" type="text" size="small">查看投票</el-button>
                        <el-button v-if="scope.row.submit.count>0" @click="click_export(scope.$index)" type="text" size="small">下载详单</el-button>
	                	<el-button v-if="scope.row.privat==1" @click="click_qrcode(scope.$index)" type="text" size="small">二维码</el-button>
	                </div>
	              </template>
	            </el-table-column>
	        </el-table>
	    </div>
	</div>
</template>
<script>
import {inject, reactive, nextTick} from 'vue'
import format_timestamp from "../plugins/utils.js"
import CKEditor from "../components/CKEditor.vue"
import XLSX from 'xlsx'
import FileSaver from 'file-saver'

export default {
	components:{ CKEditor },
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
            table_height:800,
		    now:Date.now(),
		    area:'',
		    privat:false,
		    date:'',
		    keyword:'',
		    page:1,
		    total:0,
		    size:10,
		    list: [],
		    show_vote_taked:false,//是否显示得票数
		    show_edit:false,
		    data_for_edit:{},
		    show_option_input:false,
		    option_btn_label:'',
		    show_target:false,
		    data_for_target:{},
		    def_content_for_ckeditor:'',
		    show_sum:false,
            vote_sum_width:800,
		   	sum_for_show:{},
		   	show_submit:false,
		   	submit_for_show:reactive({page:1, size:15, total:0, table_height:600, id:0, title:'', list:[]}),
	    	date_shortcut: [{
	            text: '今年一年',
	            value:()=>{
	            	const start = new Date();
	            	start.setMonth(0);
	            	start.setDate(1);
	            	const end = new Date(start.getTime());
	            	end.setMonth(11,31);
	            	return [start, end];
	            }
	        }, {
	            text: '明年一年',
	            value:()=>{
	            	const start = new Date();
	            	start.setYear(start.getFullYear()+1);
	  	        	start.setMonth(0);
	  	        	start.setDate(1);
	  	        	const end = new Date(start.getTime());
	  	        	end.setMonth(11,31);
	  	        	return [start, end];
	            }
	        }],//date_shortcut
		    show_qrcode:false,
		    qrcode_src:'',
		    cascader_config:{
	            expandTrigger:'hover',
	            checkStrictly:true,
	            lazy: true,
	            lazyLoad:(node, resolve)=>{
	                this.axios.post("/api/area/list",{id:node.value || 0}).then(ret=>{
	                    if(!ret.ok){ this.$message.error(ret.msg); return;}
	                    let nodes = [];
	                    ret.data.map(item=>{
	                        nodes.push({value:item.id, label:item.name, leaf:item.hasChildren<1});
	                    });
	                    resolve(nodes);
	                });
	            }
        	},//cascader_config
        }
    },//data end
    mounted:function(){
        this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
        this.init();
    },//mounted end
    computed:{
    },
    watch:{
        'gd.me.eid':function(){ this.load_list(); },
        area:function(){ this.page = 1; this.load_list(); },//area end
	    date:function(){ this.page = 1; this.load_list(); },//key_unit
	    keyword:function(){ this.page = 1;  this.load_list(); },//keyword end
	    privat:function(){ this.page = 1;  this.load_list(); },//privat end
    },
    methods:{
        format_date:function(stamp){ return format_timestamp(stamp); },//format_date
	    format_datetime:function(stamp){ return format_timestamp(stamp, true); },//format_datetime
	    table_row:function(data){
	    	if(data.row.block== 1){return 'blocked';}
	     	if(data.row.privat == 1){return 'privat';}
	    	return ''
	    },//table-row end
	    init: function(){
	    	this.load_list();    	
	    },//init
	    handleSizeChange:function(val){
		   	this.size = val;
		    this.page = 1;
		    this.load_list();
	    },//handleSizeChange
		handlePageChange:function(val){
			this.size = val;
		    this.load_list();
		},//handlePageChange
	    load_list:function(){
	    	let params = {page:this.page, size:this.size, area:this.area, date:this.date, key:this.keyword, privat:this.privat};
			this.axios.post("/api/vote/list",params).then(ret=>{
				if(!ret.ok){this.$message.error(ret.msg); return;}
				this.list = ret.data.list;
				this.total = ret.data.total;
			});
	    },//load_list
	    can_see_taked:function(row){
	    	//手动关闭，可以显示
	    	if(row.valid == -1){ return true; }
	    	//未设置，根据时间范围决定
	    	if(row.valid == 0){
	    		let d = new Date();
	    		//已经过了结束时间，可以显示
	    		if(Array.isArray(row.open_date) && row.open_date.length == 2 && row.open_date[1] <= d.getTime()){
	    			return true;
	    		}else{//未结束，不能显示
	    			return false;
	    		}
	    	}
	    	return false; 
	    },//can_see_taked
	    click_add:function(){//点击右上角 - 新增投票
	      this.data_for_edit = {
	        id:0,
	        title:'',
	        explain:'',
	        option:[],
	        area_path:[],
	        estate:[],
	        privat:false,
	        open_date:[],
            target_limit:0,
	        list:[],
	      };
	      this.show_option_input = false;
	      this.show_edit = true;
	    },//click_add end
	    click_edit:async function(idx){//点击主表格 - 编辑
	    	this.show_vote_taked = this.can_see_taked(this.list[idx]);
	    	if(!this.list[idx].list){
	    		let ret = await this.axios.post("/api/vote/target",{id:this.list[idx].id, withContent:1, noClick:1});
				if(!ret.ok){ this.$message.error(ret.msg); return;}
				this.list[idx].list = ret.data
			}
	    	this.data_for_edit = JSON.parse(JSON.stringify(this.list[idx]));
	    	this.show_option_input = false;
	      	this.show_edit = true;
            //console.log(this.data_for_edit);
	      	this.$nextTick(()=>{
		        let scroll = this.$refs.scroll;
                if(scroll){ scroll.scrollTop = 0; }
		        
		    });
	    },//click_edit end
	    click_block:function(idx){//点击主表格 - 上下架
	      this.axios.post("/api/vote/block", {id:this.list[idx].id}).then(ret=>{
	        if(!ret.ok){this.$message.error(ret.msg); return;}
	        this.list[idx].block = 1 - this.list[idx].block;
	        this.$message.success('操作成功');
	      });
	    },//click_block end
	    click_valid:function(idx){//点击主表格 - 开放设置
	        this.axios.post("/api/vote/valid",{id:this.list[idx].id, valid:this.list[idx].valid}).then(ret=>{
	            if(!ret.ok){this.$message.error(ret.msg); return;}
	            this.$message.success('操作成功');
	        });
	    },//click_valid
	    click_del:function(idx){//点击主表格 - 删除
	      this.$confirm('此操作将永久删除该投票, 是否继续?', '提示', {
	          confirmButtonText: '确定',
	          cancelButtonText: '取消',
	          type: 'warning'
	        }).then(() => {
	          this.axios.post("/api/vote/del", {id:this.list[idx].id}).then(ret=>{
	            if(!ret.ok){this.$message.error(ret.msg); return;}
	            this.list.splice(idx,1);
	            this.$message.success('操作成功');
	          });
	        }).catch((e) => { console.log(e); });
	    },//click_del end
	    click_sum:function(idx){//点击主表格 - 查看投票
	    	this.show_vote_taked = this.can_see_taked(this.list[idx]);
	    	this.sum_for_show = [];
	    	this.axios.post("/api/vote/detail", {id:this.list[idx].id}).then(ret=>{
	    		if(!ret.ok){ this.$message.error(ret.msg); return;}
	    		this.sum_for_show = ret.data;
                //console.log(this.sum_for_show);
	    	});
	    	this.show_sum = true;
	    },//click_sum
        click_export:function(idx){//点击主表格 - 下载详单
            this.axios.post('/api/vote/download',{id:this.list[idx].id}).then(ret=>{
                if(!ret.ok){ this.$message.error(ret.msg); return;}
                let sheet = XLSX.utils.json_to_sheet(ret.data.list,{skipHeader:true});
                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, sheet, 'sheet1');
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'binary'});
                FileSaver.saveAs(new Blob([this.s2ab(wbout)], {type: "application/octet-stream"}), ret.data.name+".xlsx")
            });
        },//click_export
        s2ab:function(s) {
          if (typeof ArrayBuffer !== 'undefined') {
            let buf = new ArrayBuffer(s.length)
            let view = new Uint8Array(buf)
            for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
            return buf
          } else {
            let buf = new Array(s.length)
            for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
            return buf
          }
        },//s2ab
	    click_qrcode:function(idx){
	    	this.qrcode_src = '';
	    	this.axios.post("/api/vote/qrcode", {id:this.list[idx].id}).then(ret=>{
	    		if(!ret.ok){ this.$message.error(ret.msg); return;}
	    		this.qrcode_src = ret.data.url;
	    		//console.log(ret.data);
	    	});
	    	this.show_qrcode = true;
	    },//click_qrcode
	    //-----------------------投票编辑框------------------------------------------------------------------------
	    click_add_option:function(){//点击按钮 - 添加投票选项
	    	this.option_btn_label = '';
	    	this.show_option_input = true;
	    	this.$nextTick(()=>{ this.$refs.option_input.focus(); })
	    },//click_add_option
	    click_add_option_done:function(){
	    	if(this.option_btn_label == ''){ return;}
	    	if(-1 < this.data_for_edit.option.indexOf(this.option_btn_label)){ return;}
	    	this.data_for_edit.option.push( this.option_btn_label );
	    	this.show_option_input = false;
	    },//click_add_option_done
	    click_del_option:function(label){
	    	//console.log(label);
	    	this.data_for_edit.option.splice( this.data_for_edit.option.indexOf(label) ,1);
	    },//click_del_option
	    click_add_target:function(){//点击按钮 - 添加目标
            if(this.data_for_edit.id > 0 && this.data_for_edit.list.length < 1){ this.$message.error("针对主题投票不能添加参选目标，请发起新的投票"); return; }
	    	this.data_for_target = {
	    		id:0,
	    		title:'',
	    		abstract:'',
	    		content:'介绍内容，详情页显示，支持多图。',
	    		cover_img:'',
	    		vote:0
	    	};
	    	this.def_content_for_ckeditor = this.data_for_target.content;
	    	this.show_target = true;
	    },//click_add_target
	    click_del_target:function(idx){//点击删除目标
	    	if(this.data_for_edit.list.length < 3){ this.$message.error("至少要保留2个参选目标"); return;}
	    	this.data_for_edit.list.splice(idx,1);
	    },//click_del_target
	    click_edit_target:function(idx){//点击按钮 - 编辑目标
	    	this.data_for_target = JSON.parse(JSON.stringify(this.data_for_edit.list[idx]));
	    	this.data_for_target.idx = idx;
	    	this.def_content_for_ckeditor = this.data_for_target.content;
	    	this.show_target = true;	      
	    },//click_edit_target
        click_target_photo_add:function(){
            let input = document.createElement("INPUT");
            input.type = "file";
            input.addEventListener('change',e=>{
                if(e.srcElement.files.length == 0){this.remove_input(input); return;}
                let file = e.srcElement.files[0];
                let formData = new FormData();
                formData.append('upload',file,file.name);
                const config = {
                    headers: { "Content-Type": "multipart/form-data;boundary="+new Date().getTime() }
                };
                this.axios.post("/api/util/image",formData,config).then(ret=>{
                    this.remove_input(input);
                    if(!ret.ok){this.$message.error(ret.msg); return;}
                    this.data_for_target.cover_img = ret.data.url;
                    //app.$forceUpdate();
                }).catch(error=>{
                    this.remove_input(input);
                    console.log(error);
                    this.$message.error(error.msg); return;
                });
            });
            document.body.appendChild(input);
            input.click();
        },//click_target_photo_add
        remove_input:function(dom){
        	if(!document.body.contains(dom)){return;}
            document.body.removeChild(dom);
        },//remove_input
        click_target_done:function(){
            if(this.data_for_target.title == ''){ this.$message.error('请输入目标名称'); return; }
            let ck_data = this.$refs.ckeditor.getContent();
            if(this.data_for_target.abstract =='' && ck_data.txt == ''){ this.$message.error('请输入介绍摘要'); return; }
            if(ck_data.html == ''){ this.$message.error('请输入介绍内容'); return; }
            this.data_for_target.content = ck_data.html;
            if( this.data_for_target.abstract == '' ){ this.data_for_target.abstract = ck_data.txt; }
            
            if(this.data_for_target.cover_img == ''){
                let reg = /<img.+?src="(.+?)".+?\/>/ig; 
                let res = reg.exec(this.data_for_target.content);
                if(res && res.length == 2){
                	this.data_for_target.cover_img = res[1];
                }                    
            }
            this.show_target = false;
            if(this.data_for_target.idx == undefined){
            	this.data_for_edit.list.push(this.data_for_target);
	            this.$nextTick(()=>{
			        let scroll = this.$refs.scroll;
			        scroll.scrollTop = scroll.scrollHeight;
			    });
            }else{
            	let idx = this.data_for_target.idx;
            	delete this.data_for_target.idx;
            	this.data_for_edit.list[idx] = this.data_for_target;
            }
        },//click_target_done
	    click_edit_done:function(){//点击编辑框 - 确定   	
	    	
			if(this.data_for_edit.title == ''){ this.$message.error('请输入投票标题'); return; }
			if(this.data_for_edit.explain == ''){ this.$message.error('请输入投票说明'); return; }
			if(this.data_for_edit.option.length < 1){ this.$message.error('请设定投票选项'); return; }
			

			//if(this.data_for_edit.list.length < 1){ this.$message.error('请添加竞选目标'); return; }
			//检查目标
			for(let i=0; i<this.data_for_edit.list.length; i++){
				let target = this.data_for_edit.list[i];
				if(target.title == ''){ this.$message.error('请输入第 '+(i+1)+' 个竞选目标名称。'); return; }
				if(target.abstract == ''){ this.$message.error('请输入第 '+(i+1)+' 个竞选目标摘要。'); return; }
				if(target.content == ''){ this.$message.error('请输入第 '+(i+1)+' 个竞选目标介绍。'); return; }
			}

			if(this.data_for_edit.list.length > 0 && this.data_for_edit.list.length < 2){
				this.$message.error("竞选目标投票 至少需要2个参选者");
				return;
			}

			this.axios.post("/api/vote/edit",this.data_for_edit).then(ret=>{
				if(!ret.ok){this.$message.error(ret.msg); return;}
				this.$message.success(ret.msg);
				this.show_edit = false;
				this.load_list();
			});
	    },//click_edit_done
	    //-----------------------统计框------------------------------------------------------------------------
	    click_target_submit:function(idx){//点击参选目标，显示投票详单
	    	if(this.gd.me.role < 4){ this.$message.error("需要权限"); return;}
	    	if(!isNaN(idx) && idx > -1){
	    		this.submit_for_show.id = this.sum_for_show.list[idx].id;
                this.submit_for_show.title = ' - '+this.sum_for_show.list[idx].title;
	    	}else{
	    		delete this.submit_for_show.id;
                this.submit_for_show.title = '';
	    	}
            this.submit_for_show.page = 1;
	    	this.show_submit = true;
	    	this.load_submit();
            //this.submit_for_show.table_height = 

	    },//click_target_submit
	    load_submit:function(){
	    	this.axios.post("/api/vote/submit_list",{id:this.sum_for_show.id, tid:this.submit_for_show.id, page:this.submit_for_show.page, size:this.submit_for_show.size}).then(ret=>{
	    		if(!ret.ok){ this.$message.error(ret.msg); return;}
	    		this.submit_for_show.total = ret.data.total;
	    		this.submit_for_show.list = ret.data.list;
                nextTick(()=>{
                    let dom = document.getElementsByClassName('el-dialog__body');
                    this.submit_for_show.table_height = dom[dom.length-1].clientHeight-10;
                    console.log(this.submit_for_show.table_height)
                });

	    	});
	    },//load_submit
	    
	    //-----------------------投票列表框--------------------------------------------------------------------
	    submit_table_handleSizeChange:function(val){
		   	this.submit_for_show.size = val;
		    this.submit_for_show.page = 1;
		    this.load_submit();
	    },//submit_table_handleSizeChange
		submit_table_handlePageChange:function(val){
			this.submit_for_show.page = val;
		    this.load_submit();
		},//submit_table_handlePageChange
    },//methods end
};
</script>