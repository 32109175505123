<template>
  <div style="height:100%;">
    <textarea :id="id" name="content"></textarea>
  </div>
</template>
<script>
import {inject} from "vue"
export default {
  props: ["show","content","height"],
  setup(){
      const gd = inject('gd');
      return {gd};
  },
  mounted:function(){ this.init(); },//mounted
  data: function() {
    return {
      id: parseInt(Math.random() * 10000).toString(),
      ckeditor: null
    };
  },
  watch: {
    height: function(){ 
    	this.init();
    },
    show:function(val){
    	if(val){ this.ckeditor.setData(this.content); }
    }
  },
  methods:{
  	getContent:function(){ return {html:this.ckeditor.getData(), txt:this.ckeditor.document.getBody().getText().substr(0,100)}; },
  	init:function(){
  		if(this.ckeditor){
  			if(this.content){ this.ckeditor.setData(this.content); }
  			return;
  		}
  		const self = this;
	    // 渲染编辑器
	    self.ckeditor = window.CKEDITOR.replace('content',{height:self.height ? self.height : '350px'});//定义编辑器的高度
	    // 设置初始内容
	    self.ckeditor.setData(self.content);

	    // 监听内容变更事件
	    // self.ckeditor.on("change", function() {
	    //   self.$emit("sendContnet", {html:self.ckeditor.getData(), txt:self.ckeditor.document.getBody().getText().substr(0,100)});
	    // });
	    //self.ckeditor.on("blur", function() { console.log("ckeditor on blur"); });
	    //监听文件上传开始
	    self.ckeditor.on( 'fileUploadRequest', evt=>{
	      var fileLoader = evt.data.fileLoader,
	      formData = new FormData(),
	      xhr = fileLoader.xhr;

	      xhr.open( 'POST', this.gd.base_url + fileLoader.uploadUrl, true );
	      formData.append( 'upload', fileLoader.file, fileLoader.fileName );
	      fileLoader.xhr.send( formData );

	      // Prevented the default behavior.
	      evt.stop();
	    });
	    //监听文件上传服务器返回
	    self.ckeditor.on("fileUploadResponse", e=>{
	      e.stop();
	      // Get XHR and response.
	      var data = e.data,
	          xhr = data.fileLoader.xhr,
	          response = JSON.parse(xhr.responseText);

	      if ( response.uploaded != 1) {
	          // An error occurred during upload.
	          data.message = response.message;
	          e.cancel();
	      } else {
	          data.url = this.gd.base_url + response.url;
	      }
	    });
  	},//init
  },//method
};
</script>
<style scoped>
</style>